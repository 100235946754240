import React, { useState, useEffect } from 'react'
import { db } from '../firebase/firebase.js'
import { collection, addDoc } from 'firebase/firestore'
import Alert from './Alert.jsx'
import SocialLinks from './SocialLinks.jsx'

const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [href, setHref] = useState('mailto:contact@bicky.dev')

  useEffect(() => {
    const host = window.location.host
    if (host) {
      setHref(`mailto:contact@${host}`)
    }
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    console.log(name, email, message)

    try {
      const docRef = await addDoc(collection(db, 'contacts'), {
        name: name,
        email: email,
        message: message,
      })
      //console.log('Document written with ID: ', docRef.id)
      setAlertMessage(`Thank you, ${name}! I will get back to you soon`)
      //alert(`Thank you, ${name}! I will get back to you soon`)
    } catch (e) {
      console.error('Error adding document: ', e)
    }

    setName('')
    setEmail('')
    setMessage('')
  }

  return (
    <div
      name="contact"
      className="w-full pt-16 bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className=" md:hidden text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
            Contact
          </p>
          <SocialLinks />
        </div>
      </div>
    </div>
  )
}

export default Contact
