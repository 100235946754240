import React from 'react'

import palthreads from '../assets/portfolio/palthreads.png'
import fiopy from '../assets/portfolio/fiopy.png'
import leanstartupcanvas from '../assets/portfolio/leanstartupcanvas.png'
import y0o from '../assets/portfolio/y0o.png'

const Projects = () => {
  const portfolios = [
    {
      id: 1,
      src: y0o,
      url: 'https://y0o.me',
      name: 'y0o.me',
      note: 'URL Shorter with analytics and dashboard',
      stack:
        'Next.js, Typescript, Tailwind, Node.js, Serverless Functions, CronJob',
    },
    {
      id: 2,
      src: palthreads,
      url: 'https://palthreads-prod.web.app/',
      name: 'Palthreads',
      note: 'Discover and Discuss Trending NFT Projects',
      stack: 'NextJS, Tailwind, Node.js, CronJob',
    },
    {
      id: 3,
      src: fiopy,
      url: 'https://fiopy-e70be.web.app/',
      name: 'Fiopy',
      note: 'Easy to use image & video classifer using Tensorflow.js',
      stack: 'Tensorflow, React',
    },
    {
      id: 4,
      src: leanstartupcanvas,
      url: 'https://leanstartupcanvas.com',
      name: 'Lean Startup Canvas',
      note: 'Free Idea Canvas for Entrepreneurs',
      stack: 'React, NoSQL',
    },
    /*
    {
      id: 5,
      src: installNode,
    },
    {
      id: 6,
      src: reactWeather,
    },
    */
  ]

  return (
    <div
      name="projects"
      className="w-full pt-16  bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Projects
          </p>
          <p className="py-6">Check out some of my personal projects.</p>
        </div>

        <div className="grid mx-auto sm:grid-cols-1 md:grid-cols-2 gap-14 md:px-12 px-2">
          {portfolios.map(({ id, src, url, name, note }) => (
            <div
              key={id}
              className="group mx-auto h-64 w-64 relative md:h-96 md:w-96 overflow-hidden rounded-lg shadow-md"
            >
              <div className="absolute left-0 top-0 h-full w-full transition-all duration-300 ease-in-out group-hover:-top-96">
                <img className="h-4/6 w-full object-cover" src={src} alt="" />
                <h1 className="md:mt-4 mt-2 px-4 text-center font-serif text-xl font-semibold text-rose-500">
                  {name}
                </h1>
                <p className="md:mt-1 px-4 text-center text-gray-200">{note}</p>
              </div>

              <div className="absolute items-center left-0 -bottom-96 flex h-full w-full flex-col justify-center transition-all duration-300 ease-in-out group-hover:bottom-0">
                <a
                  href={url}
                  className="mt-4 bg-white text-black px-4 py-2 rounded block text-center w-3/5 mx-auto"
                >
                  Visit Site
                </a>
                <h1 className="mb-2 px-8 text-center font-serif text-xl font-semibold text-rose-500 select-none mt-6">
                  {name}
                </h1>
                <p className="px-8 text-center select-none">{note}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Projects
