import React from 'react'
import HeroImage from '../assets/heroImage.jpeg'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-scroll'

const Home = () => {
  return (
    <div
      name="home"
      className="w-full pt-16 bg-gradient-to-b from-black via-black to-gray-800 md:pt-5"
    >
      <div className=" max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div className=" mt-20 flex flex-col justify-center lg:h-full h-72 md:w-[80%]">
          <h2 className="text-4xl sm:text-7xl font-bold text-white">
            I'm a Full Stack Developer
          </h2>
          <p className="text-gray-500 py-4 max-w-md pt-5">
            I have 8+ years of experience desgining and building software.
            Currently, I love to work on web applications using technologies like
            React, Tailwind, and Node.js.
          </p>

          <div>
            <Link
              to="projects"
              smooth
              duration={500}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
            >
              Projects
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </Link>
          </div>
        </div>

        <div className="mt-10 md:mt-15 md:mr-10">
          <img
            src={HeroImage}
            alt="my profile"
            className=" lg:mt-10 md:rounded-2xl mx-auto md:w-full transform transition-all scale-x-[-1] duration-300 hover:scale-110 rounded-full w-[250px] h-[250px] aspect-[9/16] sm:w-[250px] sm:h-[250px] lg:h-[360px] md:h-[260px] object-cover object-top"
          />
        </div>
      </div>
    </div>
  )
}

export default Home
