import React from 'react'

//import yumm from '../assets/portfolio/yumm.png'
import yumm from '../assets/portfolio/yumm.jpg'
import mismatchclub from '../assets/portfolio/mismatchclub.png'
import watchwithfans from '../assets/portfolio/watchwithfans.png'
import curbpals from '../assets/portfolio/curbpals.webp'

const Work = () => {
  const portfolios = [
    {
      id: 1,
      src: yumm,
      url: 'https://dev.menu.yumm.video/tdzfq/#',
      name: 'Yumm Video',
      note: 'Video menus for restaurants and food trucks.',
      stack: 'React, Next.js, Tailwind, Firebase',
    },
    /*{
      id: 2,
      src: watchwithfans,
      url: 'https://watchwithfans.live',
      name: 'Watch With Fans',
      note: 'Host watch parties on popular streaming services',
      stack: 'React, Tailwind, Netlify',
    },
    */
    {
      id: 2,
      src: curbpals,
      url: 'https://curbpals.com',
      name: 'Curb Pals',
      note: 'A subscription service that handles your trash cans.',
      stack: 'React, Tailwind, Firebase',
    },
    {
      id: 3,
      src: mismatchclub,
      url: 'https://mismatchclub.com',
      name: 'Mismatch Club',
      note: 'E-commerce store for mismatched colored Crocs.',
      stack: 'Vue.js, Tailwind, Firebase',
    },

    /*
    {
      id: 5,
      src: installNode,
    },
    {
      id: 6,
      src: reactWeather,
    },
    */
  ]
  //bg-gradient-to-b from-gray-800 to-black
  return (
    <div
      name="work"
      className="w-full pt-16 bg-gradient-to-b from-black to-gray-800 text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Work
          </p>
          <p className="py-6">Here is some of my recent work.</p>
        </div>

        <div className="grid mx-auto sm:grid-cols-1 md:grid-cols-2 gap-14 md:px-12 px-2">
          {portfolios.map(({ id, src, url, name, note }) => (
            <div
              key={id}
              className="group mx-auto h-64 w-64 relative md:h-96 md:w-96 overflow-hidden rounded-lg shadow-md"
            >
              <div className="absolute left-0 top-0 h-full w-full transition-all duration-300 ease-in-out group-hover:-top-96">
                <img className="h-4/6 w-full object-cover" src={src} alt="" />
                <h1 className="md:mt-4 mt-2 px-4 text-center font-serif text-xl font-semibold text-rose-500">
                  {name}
                </h1>
                <p className="md:mt-1 px-4 text-center text-gray-200">{note}</p>
              </div>

              <div className="absolute items-center left-0 -bottom-96 flex h-full w-full flex-col justify-center transition-all duration-300 ease-in-out group-hover:bottom-0">
                <a
                  href={url}
                  className="mt-4 bg-white text-black px-4 py-2 rounded block text-center w-3/5 mx-auto"
                >
                  Visit Site
                </a>
                <h1 className="mb-2 px-8 text-center font-serif text-xl font-semibold text-rose-500 select-none mt-6">
                  {name}
                </h1>
                <p className="px-8 text-center select-none">{note}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Work
