import React from 'react'

import html from '../assets/html.png'
import css from '../assets/css.png'
import javascript from '../assets/javascript.png'
import reactImage from '../assets/react.png'
import nextjs from '../assets/nextjs.png'
import graphql from '../assets/graphql.png'
import github from '../assets/github.png'
import tailwind from '../assets/tailwind.png'
import node from '../assets/node.png'
import sql from '../assets/sql.png'
import firebase from '../assets/firebase.png'
import netlify from '../assets/netlify.png'
import figma from '../assets/figma.png'
import sketch from '../assets/sketch.png'
import lunacy from '../assets/lunacy.png'
import davinci from '../assets/davinci.png'
import photoshop from '../assets/photoshop.png'

const Experience = () => {
  const techs = [
    {
      id: 1,
      src: html,
      title: 'HTML',
      style: 'shadow-orange-500',
    },
    {
      id: 2,
      src: css,
      title: 'CSS',
      style: 'shadow-blue-500',
    },
    {
      id: 3,
      src: javascript,
      title: 'JavaScript',
      style: 'shadow-yellow-500',
    },
    {
      id: 4,
      src: reactImage,
      title: 'React',
      style: 'shadow-blue-600',
    },
    {
      id: 5,
      src: tailwind,
      title: 'Tailwind',
      style: 'shadow-sky-400',
    },
    {
      id: 6,
      src: nextjs,
      title: 'Next JS',
      style: 'shadow-white',
    },
    {
      id: 7,
      src: node,
      title: 'Node JS',
      style: 'shadow-lime-400',
    },
    {
      id: 8,
      src: sql,
      title: 'SQL',
      style: 'shadow-blue-400',
    },
    {
      id: 9,
      src: firebase,
      title: 'Firebase',
      style: 'shadow-yellow-400',
    },
    {
      id: 10,
      src: netlify,
      title: 'Netlify',
      style: 'shadow-teal-400',
    },
    {
      id: 11,
      src: figma,
      title: 'Figma',
      style: 'shadow-purple-400',
    },
    {
      id: 12,
      src: sketch,
      title: 'Sketch',
      style: 'shadow-amber-400',
    },
    {
      id: 13,
      src: lunacy,
      title: 'Lunancy',
      style: 'shadow-sky-400',
    },
    {
      id: 14,
      src: davinci,
      title: 'Davinci Resolve',
      style: 'shadow-cyan-400',
    },
    {
      id: 15,
      src: photoshop,
      title: 'Photoshop',
      style: 'shadow-blue-400',
    },
  ]

  return (
    <div
      name="experience"
      className="w-full pt-16 bg-gradient-to-b from-black via-black to-gray-800 text-white"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
            Skills
          </p>
          <p className="py-6">These are the technologies I've worked with</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
          {techs.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
            >
              <img src={src} alt="" className="w-20 mx-auto" />
              <p className="mt-4">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Experience
