import About from './components/About'
import Contact from './components/Contact'
import Experience from './components/Experience'
import Home from './components/Home'
import NavBar from './components/NavBar'
import Projects from './components/Projects'
import Work from './components/Work'
import SocialLinks from './components/SocialLinks'

function App() {
  return (
    <div className="bg-black overflow-hidden">
      <NavBar />
      <Home />
      <About />
      <Work />
      <Projects />
      <Experience />
      <Contact />

      {/*
      <SocialLinks />
  */}
    </div>
  )
}

export default App
