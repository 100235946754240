import React from 'react'

const About = () => {
  return (
    <div
      name="about"
      className="w-full pt-16  bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>

        <p className="text-xl">
          I'm an experienced UI/UX designer turned self-taught developer
          specializing in <b>React.js</b>, <b>Node.js</b>, <b>Next.js</b>,{' '}
          <b>Tailwind CSS</b>, and <b>PostgreSQL</b> who is passionate about
          coding and building things. With a track record of creating impactful
          applications, I've successfully translated design visions into
          user-friendly software, reaching tens of thousands of users. I revel
          in the creative process of combining design aesthetics with robust
          development, striving to deliver solutions that not only function
          seamlessly but also delight users.
        </p>
      </div>
    </div>
  )
}

export default About
