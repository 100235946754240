import { getFirestore } from 'firebase/firestore'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAY8xxJyOrJdPltLNBgtaHin6T0X94C43I',
  authDomain: 'portfolio-site-743a2.firebaseapp.com',
  projectId: 'portfolio-site-743a2',
  storageBucket: 'portfolio-site-743a2.appspot.com',
  messagingSenderId: '911559942862',
  appId: '1:911559942862:web:2f82c1a5d7c238db33a69f',
}

// Initialize Firebase

const app = initializeApp(firebaseConfig)

// Get a Firestore instance
const db = getFirestore(app)

export { app, db }
